/*
* Auto hide navbar
*/
let $header = $('.navbar'),
    scrolling = false,
    previousTop = 0,
    scrollDelta = 70;

$(window).on('scroll', function(){
    if (!scrolling) {
        scrolling = true;

        if (!window.requestAnimationFrame) {
            setTimeout(autoHideHeader, 250);
        }
        else {
            requestAnimationFrame(autoHideHeader);
        }
    }
});

export const autoHideHeader = () => {
    let currentTop = $(window).scrollTop();

    if (currentTop > scrollDelta) {
        $header.removeClass('py-lg-4');
        $header.addClass('position-fixed fixed-top bg-white shadow py-lg-3');
    }
    else {
        $header.addClass('py-lg-4');
        $header.removeClass('position-fixed fixed-top bg-white shadow py-lg-3');
    }

    previousTop = currentTop;
    scrolling = false;
}