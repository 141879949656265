
import { GetAddress } from "./partials/getAddress";
import { autoHideHeader } from "./partials/header";

var supportsTouch = false;

if ('ontouchstart' in window) {
   supportsTouch = true;
} else if(window.navigator.msPointerEnabled) {
   if(navigator.msMaxTouchPoints) {
      supportsTouch = true;
   }
}

// $(window).on('load',function(){
//    if (!localStorage.getItem('city-code')) {
//       $('#cityModal').modal('show');
//    }
// });

$(function() {
   GetAddress('getAddress', true, 'city-code', 'city-checked', '.city')
   autoHideHeader()

   localStorage.setItem('showChoiceCity', true);
   // #showCityFirst
   $('button[data-show-choice="false"]').on('click', function() {
      console.log('click');
   });

   let city = localStorage.getItem('city-code');

   if (city) {
      setTimeout(function(){
         $('input[data-city="' + city + '"]').click().prop('checked', true);
         
         if ($('input[data-city="' + city + '"]').prop('checked')) {
            setTimeout(function(){
               $('.content-catalog').removeClass('loader');
            }, 100);
         }
      }, 0);
   } else {
      $('.content-catalog').removeClass('loader');

      setTimeout(function(){
         $('input[name="Filter[city][]"]').prop('checked', false);
      }, 100);
   }
   $('#filterCatalog').on('change', 'input, select', function() {
      var brandTag = $(this).data('brand');
      var typeTag = $(this).data('type');
      var cityTag = $(this).data('city');
      var categoryTag = $(this).data('category');
      var otherTag = $(this).data('other');
      if (brandTag) {
         var brandTagTemplate = '<button type="button" class="btn-sm btn bg-danger bg-opacity-25 rounded-pill d-inline-flex align-items-center me-1 mb-1 text-danger btn-tag" data-tag-type="brand" data-tag="'+brandTag+'"> Производитель:&nbsp;<b>' + brandTag + '</b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="ms-2 bi bi-x-lg" viewBox="0 0 16 16"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/></svg></button>'
         $('#tags .brand-wrap-tag').html(brandTagTemplate)
      }
      if (categoryTag) {
         var categoryTagTemplate = '<button type="button" class="btn-sm btn bg-warning bg-opacity-25 rounded-pill d-inline-flex align-items-center me-1 mb-1 text-dark btn-tag" data-tag-type="category" data-tag="'+categoryTag+'"> Цель:&nbsp;<b>' + categoryTag + '</b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="ms-2 bi bi-x-lg" viewBox="0 0 16 16"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/></svg></button>'
         $('#tags .category-wrap-tag').html(categoryTagTemplate)
      }
      if (typeTag) {
         var typeTagTemplate = '<button type="button" class="btn-sm btn bg-success bg-opacity-25 rounded-pill d-inline-flex align-items-center me-1 mb-1 text-success btn-tag" data-tag-type="type" data-tag="'+typeTag+'"> Тип:&nbsp;<b>' + typeTag + '</b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="ms-2 bi bi-x-lg" viewBox="0 0 16 16"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/></svg></button>'
         $('#tags .type-wrap-tag').html(typeTagTemplate)
      }
      if (cityTag) {
         var cityTagTemplate = '<button type="button" class="btn-sm btn bg-primary bg-opacity-25 rounded-pill d-inline-flex align-items-center me-1 mb-1 text-primary btn-tag" data-tag-type="city" data-tag="'+cityTag+'"><b>' + cityTag + '</b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="ms-2 bi bi-x-lg" viewBox="0 0 16 16"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/></svg></button>'
         $('#tags .city-wrap-tag').append(cityTagTemplate)
      }
      if (otherTag) {
         var otherTagTemplate = '<button type="button" class="btn-sm btn bg-dark bg-opacity-25 rounded-pill d-inline-flex align-items-center me-1 mb-1 text-dark btn-tag" data-tag-type="other" data-tag="'+otherTag+'"> Дополнительно:&nbsp;<b>' + otherTag + '</b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="ms-2 bi bi-x-lg" viewBox="0 0 16 16"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/></svg></button>'
         $('#tags .brand-wrap-tag').html(otherTagTemplate)
      }

      if ($('.btn.btn-tag')) {
         $('.btn.btn-tag').on('click', function(e) {
            e.preventDefault();
            var type = $(this).data('tag-type');
            var tag = $(this).data('tag');
            setTimeout(function(){
               $('input[data-'+ type +'="'+ tag +'"]').prop("checked", false);
               var $form = $('form[data-request="onFilterItems"]');
               $form.request('onFilterItems');
            }, 0)
            $(this).remove()
         });
      }
      var $form = $(this).closest('form');
      $form.request();
   });
});