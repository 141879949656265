/**
* Функция которая записывает в localStorage значение текущего города
* @el ID родительского блока, в котором находятся кнопки
* @localSorageSaving булевое значение по которому определяем сохранять ли в localStorage значение
* @setter Название значения
* @setterTitle Текст кнопки, который записываем для отображения в @elRebuild
* @elRebuild элемент для вывода текста сохраненного результата
*/

export const GetAddress = (el, localSorageSaving, setter, setterTitle, elRebuild) => {
    const getCity = () => {
        if (localStorage.getItem(setter)) {
            const city = localStorage.getItem(setterTitle);
            const cityCode = localStorage.getItem(setter);
            $(elRebuild).attr('data-city', cityCode);
            $(elRebuild).html(city);
        }
    }
    getCity();

    $('#'+el).find('button.update-city').on('click', function() {
        if (localSorageSaving) {
            localStorage.setItem(setter, $(this).data('value'));
            localStorage.setItem(setterTitle, $(this).html());
        }

        let city = localStorage.getItem('city-code');
        $('input[data-city="' + city + '"]').click().prop('checked', true);
        
        getCity();
    })
};
